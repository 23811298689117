import { lazy, Suspense } from "react";
import ErrorBoundary from "./Pages/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import NoInternetConnection from "./Pages/NoInternetConnection";
const Routes = lazy(() => import("./routes"));

function Loading() {
  return (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        width: "50%",
      }}
    >
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "47%",
          color: "primary",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Box>
  );
}

function App() {
  return (
    <NoInternetConnection>
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
    </NoInternetConnection>
  );
}

export default App;
