import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// @mui
import { CssBaseline  } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';
import { useAppContext } from '../Context/AppContext';
// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const {mode} = useAppContext();

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette,
        mode,
          ...(mode === "light" ? palette.light :  palette.dark),
      },
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      
    }),
    [mode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles mode={mode} />
          {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}

