
// ----------------------------------------------------------------------

export default function ListItemButton(theme) {
    return {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            // marginTop:0,
            // marginBottom:0,
            padding:0,
            // paddingBottom:0,
            // paddingTop:0
            
          },
        },
      },
    };
  }
  