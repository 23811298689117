import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import { onlineManager, useQuery, useQueryClient } from '@tanstack/react-query'
// import moment from 'moment';


const isDev = process.env.REACT_APP_NODE_ENV; 
const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [mode, setMode] = useState(isDev === 'development' ? 'dark' : 'light');
  const [clientId, setClientId] = useState(null);
  const [bookingId, setBookingId] = useState(null);
  const [autoChargeRoom, setAutoChargeRoom] = useState(false)
  const [vacantRooms, setVacantRooms] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isFullAmount, setIsFullAmount] = useState(false);
  const [amount, setAmount] = useState(0);
  const [phone, setPhone] = useState(''); 
  const [chargeType, setChargeType] = useState('');
  const [isSamePhoneNo, setIsSamePhone] = useState(false);
  const [addDiscount, setAddDiscount] = useState(false);
  const [AppliedDiscount, setAppliedDiscount] = useState(0);
  const [updatedTotalAmount, setUpdatedTotalAmount] = useState(0);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [kids, setKids] = useState("");
  const [adult, setAdult] = useState("");
  const [bookingType, setBookingType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [remarks, setRemarks] = useState("");
  const [endDate, setEndDate] = useState(""); 
  const  online  = onlineManager.isOnline()
  const [isOnline, setIsOnline] = useState(online)
  const roomsQuery = useQuery({ queryKey: ['rooms'], refetchOnReconnect: online })
  const categoryQuery = useQuery({ queryKey: ['categories'] })
  const settingsQuery = useQuery({ queryKey: ['settings'] })
  const revenueQuery = useQuery({ queryKey: ['revenue'] })
  const revenueMetric = useQuery({ queryKey: ['revenue/grouped'] })
  const monthlyMetrics = useQuery({ queryKey: ['revenue/monthly'] })
  const availableRooms = useQuery({ queryKey: ['rooms/vacant']})
  const clientsQuery = useQuery({ queryKey: ['clients/all']})
  const usersQuery = useQuery({ queryKey: ['users']})
  const bookingsQuery = useQuery({ queryKey: ['booking']})
  const expenseQuery = useQuery({ queryKey: ['expense']})
  const drawerWidth = 150
  const queryClient = useQueryClient()

  const updateDiscount = (newDiscount) => {
    setAppliedDiscount(newDiscount);
  };
  
  const updateAutoCharge = (value) => {
    setAutoChargeRoom(value);
  };
  const toggleDarkMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };
    
  useEffect(() => {
    setIsOnline(online);
  }, [online]);




  return (
    <AppContext.Provider value={{ 
        toggleDarkMode,
        mode,
        setMode,
        clientId, 
        setClientId,
        bookingId, 
        setBookingId,
        selectedClients,
        setSelectedClients,
        isOnline,
        categoryQuery,
        roomsQuery,
        availableRooms,
        drawerWidth,
        queryClient,
        clientsQuery,
        usersQuery,
        bookingsQuery,
        revenueQuery,
        settingsQuery,
        rooms,
        setRooms,
        paymentMethod,
        setPaymentMethod,
        totalAmount,
        setTotalAmount,
        phone,
        setPhone,
        isSamePhoneNo,
        setIsSamePhone,
        amount,
        setAmount,
        isFullAmount,
        setIsFullAmount,
        selectedRoom,
        setSelectedRoom,
        updateDiscount,
        updatedTotalAmount,
        setUpdatedTotalAmount,
        AppliedDiscount,
        setAppliedDiscount,
        expenseQuery,
        kids, 
        setKids,
        adult, 
        setAdult,
        bookingType, 
        setBookingType,
        startDate, 
        setStartDate, 
        endDate, 
        setEndDate,
        remarks, 
        setRemarks,
        addDiscount,
        setAddDiscount,
        chargeType,
        setChargeType,
        vacantRooms, 
        setVacantRooms,
        autoChargeRoom, 
        setAutoChargeRoom,
        updateAutoCharge,
        revenueMetric,
        monthlyMetrics

        
      }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};


export { AppProvider, useAppContext };
