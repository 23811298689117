import axios from 'axios';
import moment from 'moment';
import {setSessionStorageItem, getSessionStorageItem} from '../Context/sessionStorage';
// export const API_URL = 'https://server.aishadrycleaner.com/api'
export const API_URL = `${process.env.REACT_APP_HOST}/api`




export const login = async (user, token, role, date, remember) => {
  try {
    const response = await Promise.all[(
      setSessionStorageItem('user', user),
      setSessionStorageItem('token', token),
      setSessionStorageItem('role', role),
      setSessionStorageItem('date', date),
      setSessionStorageItem('rem', remember)
    )];
    
    return response
  } catch (error) {
    // console.log("error constants", error)
    throw new Error(error);
  }
};

function adjustForTimezoneOffset(date) {
  const offsetMinutes = moment().utcOffset(); // Get the timezone offset in minutes using Moment.js
  const adjustedDate = moment(date).subtract(offsetMinutes, 'minutes'); // Subtract offset minutes from the date
  return adjustedDate;
}

 
export const isAuthenticated = () => {
  const data = getSessionStorageItem('user');
  const rem = getSessionStorageItem('rem');
  const date = getSessionStorageItem('date');
  const d = adjustForTimezoneOffset(new Date(date));
  
  if (data) {
    const currentTime = new Date().getTime();
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    const ONE_MONTH_IN_MS = 30 * 24 * 60 * 60 * 1000;
    const timestamp = moment(d).valueOf();
    // console.log('date3', d);
    // console.log('value',timestamp);
    // console.log('current',  currentTime);
    // console.log('One day', ONE_DAY_IN_MS);
    if (currentTime - timestamp > ONE_DAY_IN_MS) {
      // console.log('Expired', currentTime - timestamp > ONE_DAY_IN_MS);
      sessionStorage.clear();
      window.location.reload();
      return false;
    }

    if(rem){
      if (currentTime - timestamp > ONE_MONTH_IN_MS) {
        // console.log('Expired', currentTime - timestamp > ONE_DAY_IN_MS);
        sessionStorage.clear();
        window.location.reload();
        return false;
      }
    }
    return true;
  }

  return false; // Return false if user data doesn't exist
};



export const defaultQueryFn = async ({ queryKey }) => {
  const token = getSessionStorageItem('token')
try{
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.get(
        `${API_URL}/${queryKey}`, 
        config,
      )
      return data
    } catch(error) {
      console.log(error)
      return error
    }
   
   
  }




export const callMpesaAPI = async (accountNumber, amount, phone) => {
    const response  = await axios.post(`${API_URL}/push`, {
      accountNumber,
      amount,
      phone,
      
    });
    return response.data; // You might want to return specific data or handle it differently
 
};

export const callMpesaAPI2 = async (accountNumber, amount, phone, store) => {
    const response  = await axios.post(`${API_URL}/mpesa/selfservice`, {
      accountNumber,
      amount,
      phone,
      store
      
    });
    return response.data; // You might want to return specific data or handle it differently
  
};

export const callCashAPI = async (amount, orderId, storeId, config) => {
  try {
    const payload = {
      amount, 
      orderId, 
      storeId
    }
    const response = await axios.post(`${API_URL}/cash`,payload, config );
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const callInvoiceAPI = async (requestData, config) => {
  // try {
    const response = await axios.post(
      `${API_URL}/orders`, {requestData }, config );
    return response.data;
  // } catch (error) {
  //   throw error;
  // }
};

export const callPayLaterAPI = async (requestData, config) => {
  // try {
    const response = await axios.post(
      `${API_URL}/orders`, {requestData }, config );
    return response.data;
  // } catch (error) {
  //   throw error;
  // }
};