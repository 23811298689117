// ----------------------------------------------------------------------




export default function TableCell(theme) {
    return {
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none', // Add border style
            borderBottom: 0
          },
          head: {
            color: theme.palette.text.secondary,
            backgroundColor: theme.palette.background.neutral,
            borderBottom: 'none'
  
          },

        },
      },
    
    };
  }
  