
// ----------------------------------------------------------------------

export default function ListItem(theme) {
  return {
    MuiListItem: {
      styleOverrides: {
        root: {
          marginTop:0,
          marginBottom:0,
          margin:0,
          paddingBottom:0,
          paddingTop:0
          
        },
      },
    },
  };
}
