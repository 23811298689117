import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const setSessionStorageItem = (key, value) => {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
    sessionStorage.setItem(key, encryptedValue); 
};

export const getSessionStorageItem = (key) => {
    const encryptedValue = sessionStorage.getItem(key); 
    if (encryptedValue) {
        const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedValue);
    }
    return null;
};

//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2MzU3MDBkOTViNTBlOTlkM2M5ZmE0YSIsImlhdCI6MTcxNDg3ODgxMiwiZXhwIjoxNzE0OTY1MjEyfQ.zLUqLBhBkzLFKk4RPcPBboCW38_WQX-PFXg1rJNAeQ0

export const removeSessionStorageItem = (key) => {
    sessionStorage.removeItem(key); // Use sessionStorage instead of localStorage
};