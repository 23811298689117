import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from './theme';
import { AppProvider } from './Context/AppContext';
import { QueryClient, QueryClientProvider  } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { defaultQueryFn } from './API/constant';
import { getSessionStorageItem } from './Context/sessionStorage';


const token = getSessionStorageItem('token')
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      enabled: !!token,
      staleTime: 1000 * 20,

    },
  },
  
})


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>

  <QueryClientProvider client={queryClient}>
    <AppProvider>
    <ThemeProvider>
    <App />
    {process.env.REACT_APP_NODE_ENV === 'development' ? (
      <ReactQueryDevtools initialIsOpen={false} />
     ):null}
    </ThemeProvider>
    </AppProvider>
  </QueryClientProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)



if (process.env.REACT_APP_NODE_ENV === 'production') {
  // If environment is production, register the service worker
  serviceWorker.register();
} else {
  // If environment is not production, unregister the service worker
  serviceWorker.register();
}
