// ErrorBoundary.js
import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, Grid } from "@mui/material";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  handleRetry = () => {
    window.location.reload();
  };
  
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <Grid item sm={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                textAlign: "center",
                padding: 4,
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 1,
              }}
            >
              <Typography variant="h4" color="error">
                Something went wrong.
              </Typography>
              <Typography variant="body1" mt={2}>
                We apologize for the inconvenience. An error occurred in the
                application.
              </Typography>
              <Typography variant="body2" mt={2}>
                {this.state.error && this.state.error.toString()}
              </Typography>
              <Typography variant="body2" mt={2}>
                contact your administrator
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleRetry}
                sx={{ mt: 3 }}
              >
                Retry
              </Button>
            </Box>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
