// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',  
          padding:0,
          backgroundColor:'none',
          // overflowX:'hidden',
        //   overflowY: 'auto',
        //   '&::-webkit-scrollbar': {
        //    width: '5px',
        //  },
        //  '&::-webkit-scrollbar-thumb': {
        //    borderRadius: '5px',
        //  },
        //  '&::-webkit-scrollbar-track': {
        //    backgroundColor: 'rgba(145, 158, 171, 0.12);',
        //    borderRadius: '5px',
        //  },
        // },
          
          
        },
      },
    },
  };
}
